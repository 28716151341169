import React, { Component } from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Main from './pages/main/main';
import PrivacyEN from './pages/en/privacy/privacy';
import TermsEN from './pages/en/terms/terms';

export default class App extends Component {

  render() {
    return (
      <div className="App">
        <BrowserRouter>
            <Routes>
              <Route path='/' element={<Main />} />
              <Route path='/en/privacy' element={<PrivacyEN />} />
              <Route path='/en/terms' element={<TermsEN />} />
            </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Carousel, Row, Col } from 'antd';
import Draggable from 'react-draggable'; // Both at the same time
import celestial_00 from './img/celestial_00.png';
import weibo from './img/weibo.png';
import bilibili from './img/bilibili.png';
import QQ from './img/QQ.png';
import steam from './img/steam.png';
import logo from './img/logo.png';
import './main.less';

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.next();
  }
  previous() {
    this.slider.prev();
  }
  logo() {
    return (
      <img src={logo} alt="熊掌工作室 Kumapaw Studio" style={{width: '400px'}}></img>
    )
  }
  aboutUs(mobile) {
    return (
      <div className="box" style={{width: mobile ? 'auto' : '380px', height: '180px'}}>
        <div className="boxHeaderWrap">
          <h2 className="boxHeader boxAboutUs">关于我们<span className="boxSubHeader">ABOUT US</span></h2>
        </div>
        <div className="boxBody">
          <p>熊掌工作室(KumaPaw Studio) <br/>为创造现实中无法体验的世界而生。</p>
          <p>熊魂网络/熊掌工作室于 2020 年在上海成立。<br/></p>
          <p>我们致力于创造出能让玩家沉浸其中的的 RPG 世界。</p>
        </div>
      </div>
    )
  }
  project(mobile) {
    return (
      <div className="box" style={{width: mobile ? 'auto' : '380px', height: mobile ? window.innerWidth / 2.2 + 100 : '290px'}}>
        <div className="boxHeaderWrap">
          <h2 className="boxHeader">作品<span className="boxSubHeader">PROJECTS</span></h2>
        </div>
        <div style={{marginLeft: '0px', marginRight: '0px'}}>
          <a href="https://store.steampowered.com/app/1828700/_/" target="_blank" rel="noreferrer"><img src={celestial_00} alt="celestial_project" draggable="false" style={{maxWidth: '100%', userSelect: 'none'}}></img></a>
        </div>
        <p style={{padding: '15px', margin: 0}}>
          天体计划
          <a href="https://store.steampowered.com/app/1828700/_/" target="_blank" rel="noreferrer"><img src={steam} alt="Steam_1828700" style={{height: '32px', paddingLeft: '10px'}}></img></a>
        </p>
      </div>
    )
  }
  joinUs(mobile) {
    return (
      <div className="box" style={{width: mobile ? 'auto' : '240px', height: '150px'}}>
        <div className="boxHeaderWrap">
          <h2 className="boxHeader boxHightlight">加入我们<span className="boxSubHeader">JOIN US</span></h2>
        </div>
        <div className="boxBody">
          <p>我们持续寻求有激情的游戏创作和开发成员。</p>
          <p>招聘邮箱 <a href="mailto:join@kumapaw.com">join@kumapaw.com</a></p>
        </div>
      </div>
    )
  }
  contactUs(mobile) {
    return (
      <div className="box" style={{width: mobile ? 'auto' : '240px', height: '150px'}}>
        <div className="boxHeaderWrap">
          <h2 className="boxHeader boxHightlight">联系我们<span className="boxSubHeader">CONTACT US</span></h2>
        </div>
        <div className="boxBody">
          <p>请联系 <a href="mailto:contact@kumapaw.com">contact@kumapaw.com</a></p>
          <p>或者在微博、BiliBili、QQ 联系我们！</p>
          <a href="https://weibo.com/u/7330845615" target="_blank" rel="noreferrer"><img src={weibo} alt="weibo" style={{height: '32px', paddingRight: '4px'}}></img></a>
          <a href="https://space.bilibili.com/138331" target="_blank" rel="noreferrer"><img src={bilibili} alt="bilibili" style={{height: '36px', paddingRight: '2px'}}></img></a>
          <a href="https://qm.qq.com/cgi-bin/qm/qr?k=C_JhLk-4Zlfn5weeYtuCWWGhS61kqquZ&jump_from=webapi" target="_blank" rel="noreferrer"><img src={QQ} alt="QQ" style={{height: '38px'}}></img></a>
        </div>
      </div>
    )
  }
  footer() {
    return (
      <div className="footerText">
        <p style={{marginBottom: "20px"}}>
          <a href="/en/terms" className="footerAggrement">用户协议</a> | <a href="/en/terms" className="footerAggrement">游戏使用许可及服务协议</a> | <a href="/en/privacy" className="footerAggrement">隐私政策</a>
        </p>
        <p>适龄提示：本游戏适合12岁（含）以上的用户使用</p>
        <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
        <p>亲爱的市民朋友，上海警方反诈劝阻电话“962110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。</p>
        <p style={{marginTop: "20px"}}>©2020-2024 上海熊魂网络科技有限公司 版权所有 <a target="_blank" href="https://beian.miit.gov.cn/" rel="noreferrer" className="footerLink">沪ICP备18001040号-2</a></p>
      </div>
    )
  }

  render() {
    return (
      <Row type="flex" justify="center" align="top">
        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
          <Carousel autoplay autoplaySpeed={4000} dots={false} infinite={true} ref={c => (this.slider = c)}>
            <div>
              <div className="slide slide--1"></div>
            </div>
            <div>
              <div className="slide slide--2"></div>
            </div>
            <div>
              <div className="slide slide--3"></div>
            </div>
            <div>
              <div className="slide slide--4"></div>
            </div>
          </Carousel>
          <span className="slider-pagination left" onClick={this.previous}>‹</span>
          <span className="slider-pagination right" onClick={this.next}>›</span>
          <header className="header">
            {this.logo()}
          </header>
          <div className="content">
            <Draggable defaultPosition={{x: 0, y: 140}}>
              {this.aboutUs(false)}
            </Draggable>
            <Draggable defaultPosition={{x: window.innerWidth - 650, y: -100}}>
              {this.project(false)}
            </Draggable>
            <Draggable defaultPosition={{x: 0, y: -80}}>
              {this.contactUs(false)}
            </Draggable>
            {/* <Draggable defaultPosition={{x: 260, y: -210}}>
              {this.joinUs(false)}
            </Draggable> */}
          </div>
          <footer className="footer">
            {this.footer()}
          </footer>
        </Col>
        <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="mobileBackground">
          {this.logo()}
          {this.aboutUs(true)}
          {this.project(true)}
          {this.joinUs(true)}
          {this.contactUs(true)}
          {this.footer()}
        </Col>
      </Row>
    )
  }
}

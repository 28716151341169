import React, { Component } from "react";

export default class Privacy extends Component {

  content() {
    return ( 
    <div>
      <h1><strong>KUMAPAW STUDIO PRIVACY POLICY</strong></h1>
      <p>Effective Date: October 25,2021</p>
      <h2><strong>1. Intro</strong></h2>
      <p>As Kumapaw Studio (&ldquo;<strong>Kumapaw Studio</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;) we are a technology company that designs and develops mobile games. With this Privacy Policy, we aim to transparently explain how and why we gather, store, share and use your personal data in a manner consistent with the laws of the countries in which we do business. Your privacy and the security of your personal data is, and will always be, enormously important to us. Where this Policy refers to "personal data" it is referring to any information that relates to an identified or identifiable living individual.</p>
      <p>This Privacy Policy applies to websites, mobile applications, forums and every kind of blogs of Kumapaw Studio and any other online or offline services provided by Kumapaw Studio. It also applies to our&nbsp;marketing&nbsp;and&nbsp;advertising&nbsp;activities on all platforms and other services that we may provide to you from time to time. In this Privacy Policy we refer to our games, websites, marketing and advertising activities and other services collectively as our "<strong>Services</strong>".</p>
      <p>By downloading our mobile application, accessing and/or playing our games, or interacting with our websites or other Services, you agree to the collection and use of your personal data in accordance with this Privacy Policy. If you have any other concerns about providing data to us or it being used as described in this Privacy Policy you should not use our games or other Services. This Privacy Policy is intended to meet requirements globally, including those in North America, Europe, China, Asia-Pacific countries and other jurisdictions.</p>
      <h2><strong>2. How We Collect Your Personal Data</strong></h2>
      <p>We may collect personal data directly from you or other third-party companies who have obtained your consent or have another legal right to share such personal with us (including advertising platforms and partners and data aggregators who have obtained). This may include attributes about you and your interests, as well as other games and services you use, demographic and general location information. We will use your personal data as described in this Privacy Policy.</p>
      <h3><strong>2.1 Personal Data Collected Directly from You</strong></h3>
      <p>By downloading, accessing and/or playing our games or using our other Services, you consent to the collection of your personal data by Kumapaw Studio. Most of this data comes directly from you or your device, such as your unique device ID (persistent/non-persistent), your device operating system, version, model, CPU, amount of RAM and disk space, hardware type, Identifier For Advertising (IDFA), advertising ID, the version of your operating system ("OS"), your device name, game center ID, and your location based on your Internet Protocol ("IP"). We use this data to provide our games and Services to you, including for the purposes of optimization, customizing and in-game offers and verifying purchases. We may also use it for the other purposes set out in this Privacy Policy.</p>
      <p>Depending on which of our Services you interact with we may collect and process the following types of information:</p>
      <ol>
      <li>details about how you use and interact with our games, advertising and other Services (for example, information about how and when you play our games or visit our website(s), what device you use to access our games and Services or details regarding profile visits;</li>
      <li>your interactions with us on our social media channels.</li>
      </ol>
      <p>If you choose not to create a Kumapaw Studio profile by connecting your Facebook account, the personal data that we collect when you play our games will be anonymous, but we will collect device level identifiers and other information associated with those identifiers including:</p>
      <ol>
      <li>the type of device(s) you are using to play our games or access our Services, identifiers such as IP address, device identifiers, ad identifiers, a Kumapaw Studio- player ID, and the country or region that you are playing in; and</li>
      <li>how you play our games and interact with our websites (such as level start, level end, session start, booster use, spending, rewards, your progress through the games, session time and duration, number of attempts at each level and purchases made).&nbsp;</li>
      </ol>
      <ul>
      <li><strong>Providing and optimizing our Services</strong></li>
      </ul>
      <p>We use your personal data to enable us to provide our websites, advertising and other Services to you and to optimize all of them so that we can make them the best they can be for you and all our players. This will include use and analysis of aggregated data to make sure that our websites work properly on all devices and are as enjoyable as they can be for all our players.</p>
      <ul>
      <li><strong>Customer Services</strong></li>
      </ul>
      <p>We use your personal data, including data that you provide directly to us in communications, to provide you with support through our customer service channels when you need it. We may also use it to contact you, for example as part of customer service or to send you updates about our games and Services. Finally, we will use your data to manage your account and relationship with us and improve your experience when you use our Services.</p>
      <ul>
      <li><strong>User Reviews</strong></li>
      </ul>
      <p>From time to time, you may share your comments about our games and provide certain information that may include personal data. All personal data collected from your participation in such reviews is provided by you voluntarily. We may use such personal data to improve our products, websites and/or Services and in any manner consistent with the policies provided herein.</p>
      <ul>
      <li><strong>Analytics and Research</strong></li>
      </ul>
      <p>We use analytics tools, which may include third party analytics tools, to collect information about how you play our games or use our Services. Those tools may use cookies or other similar tracking technologies. We may use your data to carry out research, surveys or to engage directly with you, for example by sending you communications (including by email), for these purposes</p>
      <ul>
      <li><strong>Promotions and Competitions</strong></li>
      </ul>
      <p>From time to time we may run promotions or competitions during which we may collect certain personal data from you, such as your name, address, post code, email address or other information or content submitted in order for you to claim a prize. Those competitions will have their own specific terms and conditions, but we will use personal data that you provide in relation to that competition for the purposes of running the competition.&nbsp;</p>
      <ul>
      <li><strong>Cheating, Crime and Fraud Prevention</strong></li>
      </ul>
      <p>We may use personal data that we hold to prevent cheating, crime or fraud. We may also need to pass your information to fraud prevention agencies and other organizations involved in crime and fraud prevention, such as the police.</p>
      <ul>
      <li><strong>Legal Uses</strong></li>
      </ul>
      <p>We may use your data as required or permitted by any applicable law.</p>
      <ul>
      <li><strong>Visiting Our Websites</strong></li>
      </ul>
      <p>When you visit our website&nbsp;<a href="https://kumapaw.com/">https://kumapaw.com/</a>&nbsp;&nbsp;(&ldquo;<strong>Website</strong>&rdquo;), we collect and process below-mentioned personal data about you&nbsp;</p>
      <h3><strong>2.2 Personal Data Collected from Third Parties</strong></h3>
      <p>As mentioned above, most of the information that we collect about you comes directly from you when you play our games, or interact with our Websites, advertising or other Services. However, when you allow third party companies to access to your personal data (e.g., downloading our mobile applications through App Store, Google Store and linking your Facebook account to mobile applications), we also collect information from such third parties.</p>
      <ul>
      <li><strong>Connection of Facebook Account</strong></li>
      </ul>
      <p>When you log in to your Kumapaw Studio profile using your Facebook account details, we will receive some of your Facebook account information. If you choose to play our games online, your basic Facebook account information will automatically be associated with your Kumapaw Studio profile. Exactly what information we receive is Facebook ID, profile picture and your name. If you do this, you will be able to use your account to log in on multiple devices and synchronize progress across those devices. Where available, you will also be able to see which of your friends are playing the same game and their progress through the games (and they will be able to see you and your progress), or access other functionality we make available in our games and other Services, such as inviting friends to play.</p>
      <p>We will also collect the email account associated with your Facebook account and the name and avatar of those of your Facebook friends that are already playing our games. You can choose not to allow us to access your email address in the Facebook dialog box the first time at which you associate your Facebook account with your Kumapaw Studio profile.&nbsp;</p>
      <p>Logging in with Facebook will not allow us to post anything to your page without your permission and we will not receive any information about your Facebook friends who do not play our game. If you do not wish your Facebook friends to be able to see you and your progression in our game, you can prevent this by disconnecting from Facebook from our game and updating your app permissions from within your Facebook settings.</p>
      <p>We would recommend that you read Facebook&rsquo;s Privacy Policy (<a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a>) and review your privacy settings from time to time to make sure that you understand fully how your Facebook data is shared.</p>
      <ul>
      <li><strong>Installation of our Mobile Applications via App Store and Google Store</strong></li>
      </ul>
      <p>When you install or purchase items related to our mobile apps (such as those you can purchase/download via App Store or Google Store), Kumapaw Studio does not have access to your credit card or bank account data. However, third party payment processors stated above may share non-financial information with us related to your purchases, like the items purchased to fulfill your order. The personal data received from the third-party companies will depend on privacy settings on relevant third-party applications.</p>
      <h2><strong>3. Purposes for Processing of Your Personal Data</strong></h2>
      <p>We collect and use your personal data for the following purposes:</p>
      <ol>
      <li>to enable us to provide our games, to ensure that your purchases are activated in the games, and to provide you with player support if you need it;</li>
      <li>to enable us to optimize our games for you and for the device that you are using, as well as to deliver customized in game events, offers and promotions;</li>
      <li>For the other purposes as set out in this Privacy Policy, including for&nbsp;marketing&nbsp;and&nbsp;advertising&nbsp;purposes;&nbsp;</li>
      <li>to operate, improve and optimize our games and other Services;</li>
      <li>to personalize our Services, understand our users and their preferences to enhance user experience and enjoyment using our Services and improve our users&rsquo; experience;</li>
      <li>to provide access to certain areas, functionalities and features of our Services;</li>
      <li>to notify you about in-game updates, new products or promotional offers;</li>
      <li>to send related information, including confirmations, technical notices, updates, and security alerts and support and administrative messages;</li>
      <li>to enable players to communicate with each other in games or on our social media accounts;</li>
      <li>to respond to your comments and inquiries and provide customer support services;</li>
      <li>to prevent potentially prohibited or illegal activities and protect of our players;</li>
      <li>to measure interest in our Services;</li>
      <li>to ensure internal quality control;</li>
      <li>to enable us to comply with laws that apply to us, to prevent fraud, to ensure compliance with our terms of service or where necessary to defend, exercise or establish our legal rights including our rights under our Terms of Service (<a href="https://kumapaw.com/en/terms">https://kumapaw.com/en/terms</a>).</li>
      <li>to contact you as a part of our recruitment processes; and</li>
      <li>as otherwise expressly set out in this Privacy Policy or as required by law.</li>
      </ol>
      <h2><strong>4. Marketing</strong></h2>
      <p>We and our third-party partners use personal data collected about you to send you marketing communications about our mobile applications and related Services based on your interests. For example, we may serve ads within our Services or place ads on third party websites and apps.</p>
      <p>We may use the information that we hold about you to promote Kumapaw Studio&rsquo; Services in a number of ways. This might include:</p>
      <ol>
      <li>advertising within third party websites and apps;</li>
      <li>offering you customized promotions for our services in the game you play;</li>
      <li>offering you in-game advertisements and special offers regarding the Kumapaw Studio services and games.&nbsp;</li>
      </ol>
      <h3><strong>4.1 What information do we use for marketing?</strong></h3>
      <p>We use information about you to try to make sure you only see marketing from us that might be of interest to you. This includes using information that we may have about you, such as:</p>
      <ol>
      <li>ad identifiers and other non-personal data collected from your device;&nbsp;</li>
      <li>the games you play and information about how you interact with our games and Services;</li>
      <li>country or region; and</li>
      <li>other information that we might acquire from our third-party marketing partners or other third parties who have obtained your consent or have another legal right to share that information with us.</li>
      </ol>
      <h3><strong>4.2 Marketing Partners</strong></h3>
      <p>When we market our games in media published by other companies, we use various third-party marketing partners to assist us on our behalf, and we may share data which we have collected about you with such third parties for these purposes.</p>
      <p>Our marketing partners may help us to serve ads to you by combining this information with data which they have collected about you elsewhere. They collect this information when you use their services or the websites and services of third parties. Our partners use this information to make assumptions about the kinds of advertising you would prefer to see. If they determine that you would be interested to see an advert for Kumapaw Studio&rsquo; games or Services, they will serve you with a Kumapaw Studio ad while you are using other websites and services.</p>
      <p>If you would prefer that your personal data is not processed to receive interest-based ads, you can submit an opt-out request by contacting us via&nbsp;<a href="mailto:privacy@kumapaw.com"><strong>privacy@kumapaw.com</strong></a>. You should note that if you opt out of receiving interest based or targeted advertising, you will still see advertising and you may see advertising for Kumapaw Studio products, but that advertising will not be targeted using your personal data.</p>
      <p>By accepting this Privacy Policy and downloading or continuing to play our games, and unless you opt out of interest-based advertising as explained in this section, you consent to our marketing partners collecting and using information about you to improve advertising, targeting and measurement systems as described in their privacy policies.</p>
      <p>This Privacy Policy does not apply to the collection of your data by our marketing partners. We recommend that you review our partners&rsquo; privacy policies for more information.</p>
      <h3><strong>4.3 Cookies Used by the Third Parties</strong></h3>
      <p>Service providers acting on our behalf, may use cookies or similar technologies to automatically collect information through the Services. Cookies are small text files placed in users&rsquo; computer browsers to store their preferences.&nbsp;Cookies belonging to such service providers may be set on your device when using our Website or Services. Cookies relating to the Website will be used by service providers for the purposes set forth below:</p>
      <ol>
      <li><strong>Operationally Necessary.</strong>Cookies may be used for the functionality of the Website.&nbsp;</li>
      <li><strong>Performance Related.</strong>Cookies may be used to assess the performance of our Website, including as part of to understand how our visitors use our Websites.&nbsp;</li>
      <li><strong>Functionality Related.</strong>Cookies may be used to provide functionality when accessing or using our Website.</li>
      </ol>
      <p>You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash Cookies operate differently than browser cookies, and cookie management tools available in a web browser may not remove flash cookies.</p>
      <p>Please be advised that if you disable cookies, you may lose some of the features and functionality of our Services because the cookies are necessary to track and enhance your activities.</p>
      <h2><strong>5. Ad identifiers, Software Development Kits and Similar Technologies</strong></h2>
      <p>To provide our games, Websites and interest-based advertising, we use ad identifiers, software development kits and similar technologies. This section describes our use of these technologies.</p>
      <p>Ad identifiers and similar technologies are used by us to provide our Services to you and in order to collect data from your device. These technologies allow us and our third-party advertising partners to:</p>
      <ol>
      <li>recognize you and your device;</li>
      <li>provide interest-based advertising to you;</li>
      <li>allow our Services to interact with a third-party social network or platform (where you have chosen to allow such interaction);</li>
      <li>enable us and third parties to provide you with more customized services, for example to provide our Services in the correct language.</li>
      </ol>
      <h3><strong>5.1 How do ad identifiers and similar technologies work?</strong></h3>
      <p>Ad identifiers are non-permanent, non-personal identifiers such as the Android advertising ID and/or Apple's ID for advertising, which are uniquely associated with your device. Similar technologies include tracking pixels within ads. These technologies allow companies (including marketers and advertisers) to recognize your device when you use Websites and applications.</p>
      <h3><strong>5.2 How to opt out from interest-based advertising on your device and Facebook</strong></h3>
      <p>Opting out of interest-based advertising at a device level: You can choose to prevent your device's ad identifier being used for interest-based advertising, or you can reset your device's ad identifier, by changing the settings of your device. Settings vary from device to device but you will usually find the ad identifier settings under "privacy" or "ads" in your device's settings.</p>
      <p>Opting out of Facebook advertising: To update your advertising preferences with Facebook, you will need to log in to your account on Facebook and update your settings there.</p>
      <h2><strong>6. Your Rights</strong></h2>
      <p>You have certain rights in connection with your personal data and how we handle it. You can exercise these rights at any time by contacting us via any of the methods set out in the&nbsp;Contact Us&nbsp;section below.</p>
      <p>Those rights include:</p>
      <ol>
      <li><strong>Access to personal data:</strong>You have the right to access your personal data that Kumapaw Studio is using.</li>
      <li><strong>Correction or deletion of personal data:</strong>You have the right to ask Kumapaw Studio to correct or complete your personal data if it is wrong or incomplete. You may also have the right to ask Kumapaw Studio to delete your personal data, when certain conditions apply.</li>
      <li><strong>Account deactivation:&nbsp;</strong>Should you wish to completely deactivate your account include disassociating your Facebook account from our mobile games and deleting our games from your mobile devices. Please note that if you completely deactivate your account all your progress in our games and any unspent virtual items will be lost and we may not be able to restore them in the future.</li>
      <li><strong>Restriction on processing of personal data:</strong>You may have the right to ask Kumapaw Studio to restrict the use of your personal data, when certain conditions apply.</li>
      <li><strong>Object to processing of personal data:&nbsp;</strong>You may have the right to object to the use of your personal data by Kumapaw Studio, when certain conditions apply.</li>
      <li><strong>Portability of personal data:&nbsp;</strong>You may have the right to receive your personal data in a structured and commonly used format so you can use this data with another company, when certain conditions apply.</li>
      <li><strong>Right to opt-out (California, USA)</strong>: California residents have the right to opt out of the sale of personal data, which is defined in a way that may include the transfer of data to third parties to personalize ads for you. If you opt out of the &lsquo;sale&rsquo; of your data, we will not sell your data, including to our advertising partners, but you may still see ads in the game and some of our partners may deliver ads to you, based on information they hold on you.&nbsp;</li>
      </ol>
      <p>There are other ways in which you can control the personal data that we collect about you. For instance, you could disconnect your Facebook or other social network account from our games, or delete our games from your mobile device. You could also reset your mobile advertising identifier or limit ad tracking altogether using the settings in your phone.&nbsp;</p>
      <p>We may occasionally send you push notifications through our mobile applications with game updates, high scores and other notices that may be of interest to you. You may at any time opt out from receiving these types of communications by changing the settings on your mobile device.</p>
      <p><strong>If you believe we have misused your data or violated any data protection regulations, you may file a complaint with a Data Protection Authority, for example, the Authority of your place of residence.</strong></p>
      <h2><strong>7. Children</strong></h2>
      <p>You must be over 13 years old to play our games and use our Services. We do not collect personal data from anyone under 13 years old person knowingly and willfully. However if the Services are used by such person in contradiction to this Privacy Policy, we may collect personal data from such person. If you believe that we might have any information from or about a child under 13 years old (or under 16 years old in certain jurisdictions in the European Union countries and/or districts), please&nbsp;Contact Us.</p>
      <h2><strong>8. Disclosure of Your Personal Data</strong></h2>
      <p>We may share your information as described in this Privacy Policy (to provide the Services with our Third-party service providers; to comply with legal obligations; to protect and defend our rights and property) with your consent.</p>
      <ol>
      <li><strong>Service Providers</strong>. We may share your personal data with our service providers. The types of service providers (data processors) to whom we entrust include companies that provides (i) provision IT and related services; (ii) provision of information and services you have requested; (iii) customer service activities; (iv) analytics and search engine activities that assist us in the improvement and optimization of the Services; and (v) any supporting activities in connection with our Services. Kumapaw Studio has executed appropriate contracts with the service providers that prohibit them from using or sharing personal data except as necessary to perform the contracted services on our behalf or to comply with applicable legal requirements.</li>
      </ol>
      <ul>
      <li><strong>Disclosures to Protect Rights of Us, You or Others (e.g., as Required by Law and Similar Disclosures).</strong>We may access, preserve, and disclose your personal data, other account information and content if we believe doing so is required or appropriate: (i) to comply with law enforcement or national security requests and legal process, such as a court order or subpoena; (ii) to respond to your requests; (iii) to protect yours, ours or others&rsquo; rights, property or safety; (iv) to enforce Kumapaw Studio&rsquo; policies or contracts; (v) to collect amounts owed to Kumapaw Studio; (vi) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or (vii) if we, in good faith, believe that disclosure is otherwise necessary or advisable.</li>
      </ul>
      <p>In addition, from time to time, server logs may be reviewed for security purposes (e.g., to detect unauthorized activity on the Services). In such cases, server log data containing IP addresses may be shared with law enforcement bodies, so they may identify users in connection with their investigation of the unauthorized activities.</p>
      <ul>
      <li><strong>Sale of Assets and Change of Control.</strong>If there is a sale of the assets of Kumapaw Studio or corporate restructuring, or as a result of a change of control in shareholding structure of Kumapaw Studio, or in preparation of any of these events. Any third party to which Kumapaw Studio transfers of sells Kumapaw Studio&rsquo; assets will have the right to continue to use the personal and other information that you provide to us in the manner set out in this Privacy Policy.</li>
      </ul>
      <h2><strong>9. International Transfer</strong></h2>
      <p>All personal data collected or stored by Kumapaw Studio may be stored anywhere in the world, including, but not limited to, in the United States, the European Union, in the cloud, on our servers and on the servers of our service providers. When we transfer your personal data internationally, we will take reasonable steps to ensure that it is treated securely, lawfully and in the manner,&nbsp;we describe here. Please note that laws vary from jurisdiction to jurisdiction, so the privacy laws applicable to the places where your personal is transferred to or stored, used or processed in may be different from the privacy laws applicable to the place where you are resident.</p>
      <p>By providing your personal data to us, you consent to the storage of your personal data in above-mentioned locations.</p>
      <p>If you are using our Services from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your personal data to China and the United States and to processing of your data globally.</p>
      <p>By choosing to visit the Website, utilize the Services or otherwise provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed by the laws of China and the adjudication of any disputes arising in connection with Kumapaw Studio or the Website will be in accordance with the terms of Terms of Service.</p>
      <h2><strong>10. Security</strong></h2>
      <p>The personal data that you provide to Kumapaw Studio is stored on servers that are located in secured facilities and protected by protocols and procedures designed to ensure industry-standard security of such data such as firewalls and data encryption. However, no server, computer or communications network or system or data transmission over the internet can be guaranteed to be 100% secure. As a result, while Kumapaw Studio strives to protect your personal data, Kumapaw Studio cannot ensure or warrant the security of any personal data you transmit to Kumapaw Studio or through the Services.</p>
      <p>By using the Services or providing personal data to us, you agree that we may communicate with you electronically regarding security privacy, and administrative issues related to your use of the Services. If we learn of a security system&rsquo;s breach, we may attempt to notify you electronically by posting a notice on the Services or sending an e-mail to you.</p>
      <h2><strong>11. Legal Bases (for European Economic Area users)</strong></h2>
      <p>If you are an individual in the European Economic Area (EEA), we collect and process personal data about you only where we have legal bases for doing so under applicable EU laws. The legal bases which we rely upon are the following:</p>
      <ul>
      <li><strong>Contractual Necessity.</strong>This covers data used by us in order to provide you with Services that you have requested - and that we have agreed - to provide to you, such as our games, customer services, chat functionality and third-party social network connectivity;</li>
      <li><strong>Legal Obligation.</strong>This covers data used by us to comply with a legal obligation, such as to maintain records relating to tax or to respond to a valid legal request for certain data;</li>
      <li><strong>Legitimate Interests.</strong>This covers data used by us for purposes that are not harmful to your privacy and can be reasonably expected within the context of your use of our Services, such as&nbsp;marketing,&nbsp;advertising, analytics, research and optimization of our games and other Services; and</li>
      <li>This is used as a legal basis in the following contexts: the collection of data stored on your device by us and our advertising partners, including device information, ad identifiers, IP address, country and region. You consent to this collection when you agree to install our game and you can withdraw your consent to this by uninstalling our games.</li>
      </ul>
      <p>In this Privacy Policy we have explained how you can opt out of certain types of processing that we undertake and where any processing is based on your consent, you can withdraw that consent at any time in the ways described in this Privacy Policy. Nonetheless, if you play our game we will still collect some personal data about you and process it as otherwise described in this Privacy Policy. If you do not wish us to collect any personal data about you at all, you should cease playing our games and stop using our Services.</p>
      <h2><strong>12. Retention</strong></h2>
      <p>We retain your data for as long as your account is active or as needed to provide you the Service.&nbsp;</p>
      <p>Note that if you ask us to remove your personal data, we will retain your data as necessary for our legitimate business interests, such as to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
      <h2><strong>13. Changes to this Privacy Policy</strong></h2>
      <p>We may update this Privacy Policy from time to time and we will post the updated Privacy Policy on this page. Any new policy will be effective from the date it is published by us.</p>
      <h2><strong>14. Contact Us</strong></h2>
      <p>To communicate with us and/or use your rights specified under this Privacy Policy please contact us at:</p>
      <p><a href="mailto:privacy@kumapaw.com"><strong>privacy@kumapaw.com</strong></a></p>
      <br/>
    </div>
    );
  }

  render() {
    return (
      <div style={{margin: "30px"}}>{this.content()}</div>
    )
  }
}
